import React from 'react';

import { TemplateSwitch } from 'src/templates';
import { makeNewTemplateRequestsForSingleLocale } from 'src/lib/contentful-utils';
import { optimizeContentfulImages } from 'src/lib/image-helpers';

const Index = (props) => {
  return <TemplateSwitch {...props} />;
};

const mapEntryToProps = ({ entry, preview }) => {
  return {
    props: { ...optimizeContentfulImages(entry), preview: !!preview },
  };
};

export async function getStaticProps({ preview }) {
  const response = await makeNewTemplateRequestsForSingleLocale('en-US', {
    'fields.url[all]': 'homepage',
  });

  const entry = response.items[0];

  if (entry) {
    return mapEntryToProps({ entry, preview });
  }

  return { props: { preview: !!preview } };
}

export default Index;
